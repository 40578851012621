import React from "react";
import Layout from "../components/layout";

import Back from "../components/backbutton";

function KontaktPage() {
  return (
    <Layout>
      <Back></Back>   
        
  <h2 className="flex p-3 mb-4 text-2xl font-bold ">
        Kontakt
  </h2>
      <section className="">      
      
      </section>    
    </Layout>
  );
}

export default KontaktPage;
